import {IPricingItem} from '../../types'

export const subscriptions: IPricingItem[] = [
  {
    id: 'free',
    price: 'Free',
    titleKey: 'subscription.free.title',
    subtitleKey: 'subscription.free.subtitle',
    featuresKeys: [
      'subscription.free.feature1',
      'subscription.free.feature2',
      'subscription.free.feature3'
    ]
  },
  {
    id: 'pro_month',
    price: '5$',
    badgeKey: 'subscription.month.badge',
    periodKey: 'subscription.month.period',
    titleKey: 'subscription.month.title',
    subtitleKey: 'subscription.month.subtitle',
    featuresKeys: [
      'subscription.month.feature1',
      'subscription.month.feature2',
      'subscription.month.feature3'
    ]
  },
  {
    id: 'pro_year',
    price: '50$',
    badgeKey: 'subscription.year.badge',
    titleKey: 'subscription.year.title',
    periodKey: 'subscription.year.period',
    subtitleKey: 'subscription.year.subtitle',
    featuresKeys: [
      'subscription.year.feature1',
      'subscription.year.feature2',
      'subscription.year.feature3'
    ]
  },
  {
    id: 'trial',
    price: 'Free',
    badgeKey: 'subscription.trial.badge',
    titleKey: 'subscription.trial.title',
    periodKey: 'subscription.trial.period',
    subtitleKey: 'subscription.trial.subtitle',
    featuresKeys: [
      'subscription.trial.feature1',
      'subscription.trial.feature2',
      'subscription.trial.feature3'
    ]
  }
]